<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="container-fluid vh-100 px-0">
    <div id="call-view-content">
      <div id="call-view-header" class="d-flex d-lg-none p-3">
        <!--displayed only if screen size is smaller than xl-->
        <call-view-header-block
          v-if="!!playbook"
          id="agenda-control-bar-sm-md-lg"
          :playbook="playbook"
          @open-playbook-selection-modal="openPlaybookSelectionModal"
          @delete-call-and-return-to-configurator="deleteCallAndReturnToConfigurator"
        >
          <template #call-navigation>
            <call-navigation
              id="playbook-navbar-sidebar"
              :items="playbookItems"
              class="sidebar"
            ></call-navigation>
          </template>
          <template #shortcuts>
            <shortcuts
              id="shortcut-container-sidebar"
              :item="mainContainer"
              class="sidebar"
              @shortcutSelected="
                objection => handleShortcutSelected(objection)
              "
            ></shortcuts>
          </template>
        </call-view-header-block>
      </div>
      <div id="call-view-body">
        <div v-if="playbookAvailable"
             id="agenda-section"
             class="h-100 overflow-hidden d-none d-lg-block">
          <!-- Navigation bar -->
          <collapsable-agenda
            id="playbook-navbar-202014121630"
            :items="playbookItems"
          >
            <!-- Should be removed later with call view redesign -->
            <template v-slot:footer>
              <control-panel/>
            </template>
          </collapsable-agenda>
        </div>
        <div id="playbook-item-and-objection-section"
             class="col px-0 call-view-body-main"
        >
          <call-view-header-and-footer
            ref="call-view-header-and-footer"
            :showSpeechRecognition="showSpeechRecognition"
            :fullTextView="callViewItemsFullText"
            :all-items-expanded="callViewItemsExpandAllItems"
            @toggle-expand-all-items="callViewItemsExpandAllItems = !callViewItemsExpandAllItems"
            @toggle-full-text-view="callViewItemsFullText = !callViewItemsFullText"
            @toggle-show-speech-recognition="showSpeechRecognition = !showSpeechRecognition"
            @input="setCounterparts"
            @clear-crm-data="clearCrmDataCount++"
            @download-crm-data="downloadCrmDataCount++"
            @open-playbook-selection-modal="openPlaybookSelectionModal"
            @delete-call-and-return-to-configurator="deleteCallAndReturnToConfigurator"
          />
          <div class="row overflow-hidden call-view-body-container">
            <div :id="callViewItemsContainerId"
                 class="fade-in col max-h-100 scroll-class"
            >
              <!-- Current playbook item display -->
              <div v-if="loading">
                <progress-indicator></progress-indicator>
              </div>
              <div v-else>
                <!-- Error -->
                <div id="error-display-2021011037" v-if="!!error">
                  <b-alert
                    variant="danger"
                    :show="!!error"
                    dismissible
                    @dismissed="handleError(null)"
                  >
                    {{ staticText.errorAlert }}:
                    <span v-html="error.data"></span>
                  </b-alert>
                </div>
                <!-- playbook Item Containers -->
                <div v-if="mainContainer" id="playbook-item-list-2021011037">
                  <call-view-item
                    v-for="(item, index) in playbookItems"
                    :key="index"
                    :item="item"
                    :main-container="mainContainer"
                    :call-view-item-index="index"
                    :full-text-view="callViewItemsFullText"
                    :all-items-expanded="callViewItemsExpandAllItems"
                    :playbook-id="playbook.id"
                    :clearCrmDataCount="clearCrmDataCount"
                    :downloadCrmDataCount="downloadCrmDataCount"
                    class="single-call-item"
                  ></call-view-item>
                </div>
              </div>
            </div>
            <!-- Contact Analysis Panel and Shortcuts-->
            <div id="objections-section" class="col-3 max-h-100 d-none d-md-block">
              <div
                v-if="playbookAvailable"
                id="objection-speech-panel-202101051052"
                class="d-flex counterpart-objections-content-wrapper h-100"
              >
                <!--  aircall  -->
                <div class="select-interlocutor d-md-none d-lg-block mb-2"
                     v-if="canUseAirCall"
                >
                  <air-call
                    :counterpart="counterparts[0]"
                    class="w-100"
                  ></air-call>
                </div>
                <div v-show="showSpeechRecognition">
                  <!--  speech recognition section  -->
                  <speech-recognition
                    v-if="shouldShowSpeechRecognition"
                    :language="userLanguage"
                    :recognition-on-call-start="true"
                    :is-conversation-edited="isConversationEdited"
                    @microphone-state-changed="handleMicStateChanged"
                    ref="speechRecognition"
                  />
                </div>

                <!--  shortcuts section  -->
                <div v-if="mainContainer"
                     class="h-100 overflow-hidden"
                >
                  <shortcuts
                    id="shortcut-container-202101051052"
                    :item="mainContainer"
                    @shortcutSelected="
                      objection => handleShortcutSelected(objection)
                    "
                  ></shortcuts>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <progress-indicator-modal
      :loading="showLoadingScreen"
      :loading-label="staticText.savingAlert"
      :ignore-timeout="true"
    ></progress-indicator-modal>

    <!-- Call Result Modal -->
    <b-modal
      v-model="showCallResultModal"
      size="lg"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      no-stacking
    >
      <template v-slot:modal-header-close>
        <img src="../../../public/img/icons/close-icon.svg"/>
      </template>
      <call-result-selector
        v-if="!!call"
        :call-id="call.id"
        @savedCallResult="handleSavedCallResult"
        @cancel="handleCancelCallResult"
      ></call-result-selector>
    </b-modal>

    <!-- Shortcuts modal-->
    <call-view-objection-modal></call-view-objection-modal>

    <!-- Modal to show warning on navigation-->
    <b-modal
      ref="navigation-warning-modal-202101051054"
      id="navigation-warning-modal-202101051054"
      :title="staticText.navigationModalTitle"
      :cancel-title="staticText.cancelLabel"
    >
      <template v-slot:modal-header-close>
        <img src="../../../public/img/icons/close-icon.svg"/>
      </template>
      <b-alert variant="warning" show>
        <p>
          {{ navigationModalText1 }}
        </p>

        <p>
          {{ staticText.navigationModalText2 }}
        </p>
      </b-alert>
    </b-modal>

    <!-- Playbook Selection Modal -->
    <playbook-selection-modal
      v-if="playbook"
      ref="playbookSelectionModal"
      :active-playbook="playbook"
      @change-active-playbook="changeActivePlaybook"
    />

    <!-- Modal to show warning when changing playbook during call-->
    <b-modal
      ref="changing-playbook-warning-modal-202211281940"
      id="changing-playbook-warning-modal-202211281940"
      :title="staticText.navigationModalTitle"
    >
      <template v-slot:modal-header-close>
        <img src="../../../public/img/icons/close-icon.svg"/>
      </template>
      <b-alert variant="warning" show>
        <p class="m-0">
          {{ staticText.changingPlaybookWarningText }}
        </p>
      </b-alert>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="$refs['changing-playbook-warning-modal-202211281940'].hide()"
        >
          {{ staticText.cancelLabel }}
        </button>
        <button
          class="btn btn-primary"
          @click="deleteInitialCallAndStartNewOne"
        >
          {{ staticText.confirmLabel }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import Shortcuts from "./CallViewItem_components/Shortcuts"
import CallViewItem from "./CallViewItem"
import { baoDelayService, callMainApp, callWrapUpUrl } from "./index"
import CallNavigation from "./CallViewNavigation"
import CallViewHeaderAndFooter from "./CallViewHeaderAndFooter"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "../base/ProgressIndicator"
import ProgressIndicatorModal from "../base/ProgressIndicatorModal"
import SpeechRecognition from "./CallViewItem_components/SpeechRecognition"
import CallResultSelector from "../callresult/CallResultSelector"
import {
  currentCallStore,
  setupNewCallStore
} from "@/store/services/callStore"
import CallViewObjectionModal from "@/apps/call/CallViewItem_components/CallViewObjectionModal"
import CallViewHeaderBlock from "@/apps/call/CallViewHeaderBlock"
import AudioRecorder from "@/apps/call/audioProcessing/audioRecorder"
import LiveTranscriber from "@/apps/call/audioProcessing/liveTranscriber"
import AirCall from "@/apps/call/AirCall"
import ControlPanel from "@/apps/call/ControlPanel"
import CollapsableAgenda from "@/apps/call/CollapsableAgenda"
import { mapStateToCurrentCallStore } from "./utils"
import PlaybookSelectionModal from "@/apps/call/CallViewItem_components/PlaybookSelectionModal"
import appInfo from "@/apps/call/index"

const getInitialData = () => {
  return {
    axios,
    callMainApp,
    staticTextDefault: {
      errorAlert: "Error",
      navigationModalTitle: "Warning",
      normalModeNavigationModalText1:
        "Navigating away from this page can lead to data loss.\n" +
        "To be sure please click 'End Conversation' in the upper right corner of the screen.",
      navigationModalText2: "Are you sure you want to leave?",
      previewModeNavigationModalText1:
        "Navigating away from this page can lead to data loss.\n" +
        "To return to the configurator, click on the back button next to the playbook title.",
      changingPlaybookWarningText: "If you change the playbook, all notes and information taken so far in the current conversation will be lost.",
      cancelLabel: "Cancel",
      confirmLabel: "Confirm"
    },
    callViewItemsFullText: true,
    callViewItemsExpandAllItems: true,
    isBaoCallRecordActive: false,
    newPlaybook: null,
    clearCrmDataCount: 0,
    downloadCrmDataCount: 0
  }
}

export default {
  name: "CallView",
  components: {
    CallViewObjectionModal,
    CallResultSelector,
    ControlPanel,
    SpeechRecognition,
    ProgressIndicatorModal,
    ProgressIndicator,
    CallNavigation,
    CollapsableAgenda,
    CallViewItem,
    Shortcuts,
    CallViewHeaderBlock,
    AirCall,
    PlaybookSelectionModal,
    CallViewHeaderAndFooter
  },
  data () {
    return {
      ...getInitialData(),
      showSpeechRecognition: true,
      liveTranscriber: null,
      audioRecorder: null
    }
  },
  computed: {
    ...mapStateToCurrentCallStore([
      "callViewItemsContainerId"
    ]),
    ...mapGetters({
      canUseSpeechRecognition: "auth/canUseSpeechRecognition",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canUseAirCall: "auth/canUseAirCall",
      user: "auth/user",
      audioLanguage: "auth/userAudioInputLanguage",
      canUseLiveTranscription: "auth/canUseLiveTranscription",
      canUseSaveToCrmFeature: "auth/canUseSaveToCrmFeature"
    }),
    callAudioLanguage () {
      return this.playbook && this.playbook.language ? this.playbook.language : this.audioLanguage
    },
    call () {
      return currentCallStore.call
    },
    mainContainer () {
      return currentCallStore.mainContainer
    },
    playbook () {
      return currentCallStore.playbook
    },
    playbookItems () {
      return currentCallStore.playbookItems
    },
    counterparts () {
      return currentCallStore.counterparts
    },
    error () {
      return currentCallStore.error
    },
    loading () {
      return currentCallStore.loading
    },
    activeItem () {
      return currentCallStore.activeItem
    },
    saving () {
      return currentCallStore.saving
    },
    showCallResultModal () {
      return currentCallStore.showCallResultModal
    },
    isCallActive () {
      return currentCallStore.isCallActive
    },
    getUserId () {
      return currentCallStore.getUserId
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    playbookAvailable () {
      return !!this.call && !!this.playbook && !!this.playbookItems
    },
    callIdFromUrl () {
      return this.$route.params.callId ? this.$route.params.callId : null
    },
    playbookIdFromUrl () {
      return this.$route.query && this.$route.query.playbookId
        ? this.$route.query.playbookId
        : null
    },
    showLoadingScreen () {
      return this.saving && !this.showCallResultModal
    },
    userLanguage () {
      return this.user.language === "de" ? "de-DE" : "en-US"
    },
    ...mapGetters({
      canUseSpeechRecognition: "auth/canUseSpeechRecognition",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canUseAirCall: "auth/canUseAirCall",
      user: "auth/user",
      audioLanguage: "auth/userAudioInputLanguage",
      canUseLiveTranscription: "auth/canUseLiveTranscription"
    }),
    isConversationEdited () {
      return !!this.$route.query.editConversation
    },
    isPreviewMode () {
      return !!this.$route.query.previewMode
    },
    isCallNewAndNotPreviewMode () {
      return !(this.isConversationEdited || this.isPreviewMode)
    },
    shouldShowSpeechRecognition () {
      return !this.isPreviewMode && this.canUseSpeechRecognition && !this.loading
    },
    navigationModalText1 () {
      return this.isPreviewMode ? this.staticText.previewModeNavigationModalText1 : this.staticText.normalModeNavigationModalText1
    }
  },
  async created () {
    await this.setUp()
    await this.getCounterpartsFromUrl()
  },
  async mounted () {
    await this.fetchAllPlaybooks()

    if (this.isCallNewAndNotPreviewMode) {
      this.notifyBaoSwiftCallStarted()
      await this.initializeRecorderAndTranscriber()
    }
  },
  beforeRouteLeave (to, from, next) {
    // This section just ensures if a user leaves the callView, the audio recording stops
    // Managing this in callStore is very tedious and error-prone
    // Trying to set a getter and watcher also doesn't work as we create new stores for each call
    if (to.path.includes(callWrapUpUrl) || this.isApplicationInIFrame || (this.isPreviewMode && to.path.includes("templates/edit"))) {
      if (this.isCallNewAndNotPreviewMode && this.canUseBaoAudio) this.stopAudioProcessing()
      next()
      return
    }

    this.$refs["navigation-warning-modal-202101051054"].show()
    this.$nextTick(() => {
      // We set up hide handler in 'next tick' to ensure Modal is in DOM
      this.$refs["navigation-warning-modal-202101051054"].$once(
        "hide",
        bvEvt => {
          if (bvEvt.trigger !== "ok") {
            next(false)
            return
          }
          // User clicked on built in OK button, so we set up a handler to listen for modal 'hidden' event
          // and advance to next route once hidden
          const handleRouteLeave = () => {
            if (this.isCallNewAndNotPreviewMode && this.canUseBaoAudio) this.stopAudioProcessing()
            if (this.isPreviewMode) this.deleteInitialCall(this.callIdFromUrl)
            next()
          }
          this.$refs["navigation-warning-modal-202101051054"].$once(
            "hidden",
            handleRouteLeave
          )
        }
      )
    })
  },
  methods: {
    ...mapActions({
      fetchAllPlaybooks: "playbook/fetchAllPlaybooks"
    }),
    ...baoDelayService.methods,
    notifyBaoSwiftCallStarted () {
      if (this.isApplicationInIFrame) this.messageBaoSwift({ type: "callStarted" })
    },
    handleShortcutSelected (objection) {
      // payload should be of the form {objection, item}
      const payload = { objection, item: this.activeItem }
      currentCallStore.additionalDataSelected({ payload, isObjection: true })
    },
    handleCancelCallResult () {
      currentCallStore.handleCancelCallResult()
    },
    handleSavedCallResult (result) {
      currentCallStore.handleSavedCallResult(result)
    },
    handleError (error) {
      currentCallStore.handleError(error)
    },
    setCounterparts (counterparts) {
      currentCallStore.setCounterparts(counterparts)
    },
    setUp () {
      return new Promise(resolve => {
        // handles the store setup
        setupNewCallStore(this.callIdFromUrl, this.$store)
        let promise = null
        if (!this.callIdFromUrl && this.playbookIdFromUrl) {
          promise = this.createNewCall()
        } else if (this.callIdFromUrl) {
          promise = currentCallStore.loadCall(this.callIdFromUrl)
        } else {
          promise = this.redirectToCallSetup()
        }
        promise.then(() => {
          currentCallStore.storeCallViewComponent(this)
          resolve()
        })
      })
    },
    createNewCall () {
      const that = this
      return new Promise((resolve, reject) => {
        const data = {
          user: this.getUserId,
          talkscript: this.playbookIdFromUrl
        }
        const externalCallId = this.$route.query.external_call_id
        if (externalCallId) data.external_call_id = externalCallId
        currentCallStore
          .createCall(data)
          .then(newCall => {
            return that.$router
              .push({
                path: that.callMainApp.path.replace(":callId", newCall.id),
                query: that.$route.query
              })
              .then(() => {
                return currentCallStore.loadCall(newCall.id).then(resolve)
              })
          })
          .catch(error => {
            this.handleError(error)
            reject(error)
          })
      })
    },
    redirectToCallSetup () {
      return new Promise((resolve, reject) => {
        // Without setting endConversation to true and navigating gives a warning. Hence, endConversation must be set
        currentCallStore
          .setEndConversation(true)
          .then(() => {
            return this.pushOrReplaceRoute({ path: "/call" }).then(resolve)
          })
          .catch(error => {
            this.handleError(error)
            reject(error)
          })
      })
    },
    async initializeRecorderAndTranscriber () {
      console.log("CR: ", "canUseBaoAudio: ", this.canUseBaoAudio,
        "canUseLiveTranscription: ", this.canUseLiveTranscription,
        "audioLanguage:", this.callAudioLanguage)
      if (this.canUseBaoAudio) {
        this.audioRecorder = new AudioRecorder()
        if (this.canUseLiveTranscription && this.callAudioLanguage) {
          this.liveTranscriber = new LiveTranscriber()
        }
        await this.startAudioProcessing()
      }
    },
    async resetRecorderAndTranscriber () {
      if (this.audioRecorder) {
        await this.audioRecorder.resetState()
        this.audioRecorder = null
      }
      if (this.liveTranscriber) {
        await this.liveTranscriber.resetState()
        this.liveTranscriber = null
      }
    },
    async startAudioProcessing () {
      if (this.audioRecorder) {
        await this.audioRecorder.startRecording()
      }
      if (this.liveTranscriber) {
        await this.liveTranscriber.startTranscribing(this.callAudioLanguage)
      }
    },
    async stopAudioProcessing () {
      // Don't need await here, both requests can be done in parallel
      if (this.audioRecorder) {
        this.audioRecorder.stopRecording()
      }
      if (this.liveTranscriber) {
        this.liveTranscriber.stopTranscribing()
      }
    },
    async handleMicStateChanged (granted) {
      if (!this.canUseBaoAudio) return

      if (granted) {
        await this.resetRecorderAndTranscriber()
        await this.initializeRecorderAndTranscriber()
      }
    },
    getCounterpartsFromUrl () {
      const crmService = this.$route.query.crm_service
      if (!crmService) {
        return Promise.resolve()
      }
      return new Promise((resolve, reject) => {
        const url = "/api/counterpartsnew/get_counterpart?service=" + crmService
        const data = this.$route.query
        return this.axios.post(url, data).then((response) => {
          const newCounterpart = response.data
          const newCounterparts = this.counterparts ? JSON.parse(JSON.stringify(this.counterparts)) : []
          newCounterparts.push(newCounterpart)
          this.setCounterparts(newCounterparts)
          if (this.canUseSaveToCrmFeature) this.$refs["call-view-header-and-footer"].downloadCrmData()
          resolve()
        })
      })
    },
    openPlaybookSelectionModal () {
      this.$refs.playbookSelectionModal.openModal()
    },
    changeActivePlaybook (newPlaybook) {
      this.newPlaybook = newPlaybook
      this.$refs["changing-playbook-warning-modal-202211281940"].show()
    },
    async deleteCallAndReturnToConfigurator () {
      await this.deleteInitialCall(this.callIdFromUrl)

      this.$router.push(`/templates/edit/${this.playbook.id}`)
    },
    async deleteInitialCallAndStartNewOne () {
      this.$refs["changing-playbook-warning-modal-202211281940"].hide()
      const initialCallId = this.callIdFromUrl
      // This is required to stop and cleanup the speech recognition, else it will keep running in the background
      this.$refs.speechRecognition.stopSpeechRecognitionAndCleanup()
      const newCallId = await this.getNewCallId(this.newPlaybook.id)
      this.$router.push({ path: this.callMainApp.path.replace(":callId", newCallId) })
      this.setUp()
      this.deleteInitialCall(initialCallId)
      await this.resetRecorderAndTranscriber()
      await this.initializeRecorderAndTranscriber()
    },
    async getNewCallId (playbookId) {
      const that = this
      const callData = {
        user: that.user.pk,
        counterparts: that.counterparts,
        talkscript: playbookId
      }

      const { data } = await this.axios.post(appInfo.apiUrl, callData)
      return data.id
    },
    async deleteInitialCall (callId) {
      try {
        this.axios.delete(`${appInfo.apiUrl}/${callId}`)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
#call-view-content {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

#call-view-body {
  display: flex;
  overflow: hidden;
  height: 100%;
  padding: 12px 24px 20px 0;
  @include media-breakpoint-down(md) {
    padding: 0 10px;
  }
}

.call-view-body-main {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    padding-bottom: 16px;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.counterpart-objections-content-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.sidebar {
  margin-bottom: 90px;
}

.scroll-class {
  overflow-y: scroll;
  padding-right: 7px;
}

.nav-pills .nav-link.active {
  background-color: $white;
  border-radius: 25px;
  border: 1px solid #007bff;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.select-interlocutor {
  padding: 12px;
  background: $white40;
  box-shadow: 6px 8px 20px 5px rgba(179, 173, 159, 0.12);
  border-radius: 12px;
}

.single-call-item {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

</style>
